class Synapse {
    constructor(appId, clientId) {
        var reqUri = "wss://synapse.dev.qarts.eu/connect_synapse?application_id="+appId+"&client_id="+clientId;
        this.connect(reqUri);
    }

    connect(reqUri) {
        console.log("Connecting to " + reqUri);
        try {
            this.socket = new WebSocket(reqUri);

            this.socket.onopen = function (msg) {
                console.log("Welcome - status " + this.readyState);
                console.log(msg);
            };

            this.socket.onmessage = function (msg) {
                var data = msg.data;
                var jsonData = {};

                try {
                    jsonData = JSON.parse(data);
                } catch (e) {
                    jsonData = {};
                }
                console.log(jsonData);
            };

            this.socket.onclose =  (event)  => {
                console.log("Disconnected - status " + this.readyState + ". Will reconnect now");
                console.log(event);
                this.connect(reqUri);
            };

            this.socket.onerror = function (event) {
                console.log(event);
            }
            console.log(this.socket);
        } catch (ex) {
            console.log(ex);
        }
    }

    onMessage(callback) {
        this.socket.onmessage = function (message ) {
            callback(message.data);
        }
    }

}

export default Synapse;
