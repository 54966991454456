<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('message-board')"></content-header>
        <div class="w3-right w3-margin-right flow-margin-top">
          <i class="fa fa-plus flow-blue-color w3-xlarge cursor-pointer w3-margin-right" @click="openNewForm = true"></i>
        </div>
      </div>
    </div>

    <div class="content-sidebars sidebars-70-30 ">
      <div v-if="openNewForm" class="w3-container content-sidebar-right">
        <i class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max" @click="openNewForm = false"></i>
        <new-message :onClose="() => {openNewForm = false}" @newMessageAdded="updateMessagesList($event)"
                      :messageImportanceOptions="messageImportanceOptions"></new-message>
      </div>

      <div class="content-sidebars sidebars-70-30 ">
        <div v-if="openEditForm" class="w3-container content-sidebar-right">
          <i class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max" @click="openEditForm = false"></i>
          <edit-message :onClose="() => {openEditForm = false}" @updatedMessage="updateMessagesList($event)"
                                    :messageImportanceOptions="messageImportanceOptions"
                                    :currentMessage="currentMessage"></edit-message>
        </div>
      </div>
      <div v-bind:class="openNewForm || openEditForm? 'content-sidebar-left' : ''">
        <message-list :newMessageAdded="newMessageAdded" :openNewForm="openNewForm"
                                  :messages = "messages"
                                  :openEditForm="openEditForm"
                                  @openMessageDetails="openEditSideBar"
        ></message-list>
      </div>
    </div>

  </div>
</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import MessageList from "@/components/views/message-board/MessageList.vue";
import EditMessage from "@/components/views/message-board/EditMessage.vue";
import NewMessage from "@/components/views/message-board/NewMessage.vue";

export default {
  name: "Messages",
  components: {ContentHeader, EditMessage, NewMessage, MessageList},
  data() {
    return {
      openNewForm: false,
      openEditForm: false,
      messages: [],
      newMessageAdded: false,
      currentMessage: "",
      messageImportanceOptions: [
        {
          name: this.$t("normal"),
          id: 'NORMAL'
        },
        {
          name: this.$t("high"),
          id: 'HIGH'
        }
      ],
    }
  },
  mounted() {
    this.getAllMessages();
  },

  methods: {

    getAllMessages() {
      const app = this;
      app.messages = [];
      app.$restClient.messages.all().then(response => {
        let data = response.data;
        if (data.length > 0) {
          app.messages = data;
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    openEditSideBar(_message) {
      this.currentMessage = _message;
      this.openEditForm = true;
    },

    openNewSideBar() {
      this.openNewForm = true;
    },

    updateMessagesList (message) {
      this.newMessageAdded = message;
      this.openEditForm = false;
      this.openNewForm = false;
      this.getAllMessages()
    }
  }
}
</script>