<template>
  <i
      class="fa fa-plus flow-blue-color w3-xlarge cursor-pointer w3-margin-right"
      @click="sideFormStatusEvent('create')"
  ></i>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: 'PlusButton',
  methods: {
    ...mapActions({
      sideFormStatusEvent: "eventHandlers/sideFormStatusEvent"
    })
  }
}
</script>