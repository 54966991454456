class Authentication {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            VERIFY: '/api/auth/verify',
            WHO_AM_I: '/api/whoami',
            AUTH_BROWSER: '/api/auth/browser?redirectUri=' + httpMethods.apiConfig.redirectUrl,
            LOG_OUT: '/api/auth/logout?redirectUri=' + httpMethods.apiConfig.redirectUrl
        };
    }

    verify() {
        return this.api.get(this.EP.VERIFY);
    }

    whoAmI() {
        return this.api.get(this.EP.WHO_AM_I);
    }

    login() {
        window.location = this.api.apiConfig.host + this.EP.AUTH_BROWSER
    }

    logout() {
        window.location = this.api.apiConfig.host + this.EP.LOG_OUT
    }

}

export default Authentication;