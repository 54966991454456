<template>
    <span class="flow-margin-left font-weight-bold" v-if="propsVisitor">
      <span v-if="propsVisitor.salutation">{{ propsVisitor.salutation }}.</span>
      <span v-if="propsVisitor.firstName">&nbsp;{{ propsVisitor.firstName }}</span>
      <span v-if="propsVisitor.lastName">&nbsp;{{ propsVisitor.lastName }}</span>
      <span v-if="propsVisitor.nationalNumber">&nbsp;({{ propsVisitor.nationalNumber }})</span>
      <span v-if="propsVisitor.iklNumber"> - {{ propsVisitor.iklNumber }}</span>
    </span>
</template>

<script>
export default {
  props: ['propsVisitor'],
  name: "VisitorNameHeader"
}
</script>