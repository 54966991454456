export default {
    openingHourSideBarStatusEvent(state, payload) {
        state.openingHourSideBarStatus = payload;
    },
    selectOpeningHourEvent(state, openingHour) {
        state.selectedOpeningHour = openingHour;
    },
    selectDomainEvent(state, domain) {
        state.selectedDomain = domain;
    },
    sideFormStatusEvent(state, payload) {
        state.sideFormStatus = payload;
    },
    domainTypesEvent(state, domainTypes) {
        state.domainTypes = domainTypes;
    },
    standardTextsEvent(state, standardTexts) {
        state.standardTexts = standardTexts;
    },
    createdDomainEvent(state, domain) {
        state.createdDomain = domain;
    },
    updatedDomainEvent(state, domain) {
        state.updatedDomain = domain;
    }
}