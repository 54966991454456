<template>
    <p>placeholder</p>
</template>

<script>
export default {
    mounted() {
        const app = this;
        app.$restClient.testing.getFirstData().then (response => {
            console.log(response);
            app.$notify.notifySuccess({title: "success", message:"this is the first message"});
        })
        app.$restClient.secondTesting.getSecondData().then (response => {
            app.$store.dispatch('users/setUserName',response.data.species.name)
            app.$notify.notifySuccess({title: "greater success", message:"this is the message"});
        })
        app.$restClient.secondTesting.get404().then (() => {
            console.log("the response went through anyway");
        }).catch( () => {
            console.log("the catch got called")
        })
    }
}
</script>