<template>
  <div v-bind:class="moveTableLeft ? 'content-sidebar-left' : ''">
    <div class="flow-card blue-top w3-padding qpp-margin-sides-5pc w-85pc">
      <table class="w3-table w3-bordered flow-table">
        <thead>
          <tr class="table-header-row">
            <th v-for="(column, index) in columns" :key="index">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody v-show="data.length === 0">
          <tr>
            <td class="w3-center flow-blue-color" colspan="8">
              <b class="font-size-1pt25em">{{ $t(noContent) }}</b>
            </td>
          </tr>
        </tbody>

        <tbody
          v-if="isClickable"
          v-show="data.length > 0"
          class="cursor-pointer"
        >
          <tr
            name="clickableRow"
            v-for="(record, rowIndex) in data"
            :key="rowIndex"
            @click="rowSelectedEvent(record)"
          >
            <td v-for="(column, colIndex) in columns" :key="colIndex">
              <span class="w3-tooltip">
                <span>{{
                  getTruncatedValue(record, column.dataKey, column.separator)
                }}</span>
                <span
                  class="w3-text w3-tag standard-text-hover w3-tooltip"
                  v-if="
                    getValue(record, column.dataKey, column.separator) !==
                      undefined &&
                      getValue(record, column.dataKey, column.separator)
                        .length >= maxLength
                  "
                >
                  {{ getValue(record, column.dataKey, column.separator) }}
                </span>
              </span>
            </td>
          </tr>
        </tbody>
        <tbody
          v-if="!isClickable"
          v-show="data.length !== 0"
          class="cursor-pointer"
        >
          <tr
            name="unClickableRow"
            v-for="(record, rowIndex) in data"
            :key="rowIndex"
          >
            <td v-for="(column, colIndex) in columns" :key="colIndex">
              <span class="w3-tooltip">
                <span>{{
                  getTruncatedValue(record, column.dataKey, column.separator)
                }}</span>
                <span
                  class="w3-text w3-tag standard-text-hover"
                  v-if="
                    getValue(record, column.dataKey, column.separator) !==
                      undefined &&
                      getValue(record, column.dataKey, column.separator)
                        .length >= maxLength
                  "
                >
                  {{ getValue(record, column.dataKey, column.separator) }}
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/**
 * Reusable table in which you can set the columns you want
 */
export default {
  name: "StandardTable",
  props: {
    /**
     * Moves the table left when the sidebar on the right opens
     */
    moveTableLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * This is a configuration. When set to true, a record's data gets emitted to the parent when you click on it.
     */
    isClickable: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Array in which you define the key, label and what kind of data to link to it for each column + an optional separator
     * in case you want multiple dataKeys in one column.
     * <p><p>
     *   Example:<p>
     *           columnDefinitions: [<p>
     *         {<p> key: "day", label: this.$t("day"), dataKey: "day.value" <p>},<p>
     *         {<p> key: "regime", label: this.$t("regime"), dataKey: "regimeName" <p>},<p>
     *         {<p>
     *           key: "starttime",<p>
     *           label: this.$t('start-time') + " - " + this.$t('end-time'),<p>
     *           dataKey: "startTime--endTime",<p>
     *           separator: " - "<p>
     *         }<p>
     *       ]
     */
    columnDefinitions: {
      type: Array,
      required: true
    },
    /**
     * All the data you want to present in the table
     */
    data: {
      type: Array,
      required: true
    },
    /**
     * Needs to be filled in with a translation key from nl.json
     */
    noContent: {
      type: String,
      required: true
    }
  },
  emits: ["openSideBar", "selectedRecord"],
  data() {
    return {
      contentLoaded: true,
      columns: [],
      maxLength: 45
    };
  },
  mounted() {
    this.columns = this.columnDefinitions;
  },
  methods: {
    rowSelectedEvent(record) {
      this.$emit("selectedRecord", record);
    },
    getValue(rowData, dataKey, separator) {
      // Function to get value from nested object using dot notation
      if (separator === undefined || separator === null) separator = "";
      let resultValue = undefined;

      const multiColumnKeys = dataKey.split("--");
      multiColumnKeys.forEach(columnKey => {
        if (columnKey.includes(".")) {
          const keys = columnKey.split(".");
          let value = rowData;
          keys.forEach(key => {
            value = value[key];
          });
          if (resultValue === undefined) resultValue = value;
          else resultValue += separator + value;
        } else {
          if (resultValue === undefined) resultValue = rowData[columnKey];
          else resultValue += separator + rowData[columnKey];
        }
      });
      return resultValue;
    },
    setValue(rowData, dataKey, separator) {
      this.value = this.getValue(rowData, dataKey, separator);
    },
    getTruncatedValue(rowData, dataKey, separator) {
      return this.$helper.truncate(
        this.getValue(rowData, dataKey, separator),
        this.maxLength
      );
    }
  },
  computed: {}
};
</script>

<style scoped>
.table-header-row:hover {
  background-color: white;
}
</style>