class Visitors {
    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            UPDATE_VISITOR: '/api/sites/:siteId/visitors/:visitorId',
            GET_VISITORS: '/api/sites/:siteId/visitors?day=:date',
        };
    }

    updateVisitor(visitor){
        let url = this.EP.UPDATE_VISITOR;
        let data = {
            id: visitor.id,
            firstName: visitor.firstName,
            lastName: visitor.lastName,
            comment: visitor.comment
        }
        return this.api.put(url, data, {
            visitorId: visitor.id,
        });
    }

    getVisitors(day) {
        const url = this.EP.GET_VISITORS;
        return this.api.get(url, {
            date: day,
        })
    }
}

export default Visitors;