<template>
  <i
    class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max"
    @click="onClicked"
  ></i>
  <!-- x icon for closing the sidebar -->
</template>

<script>
export default {
  name: "CloseButton",
  props: {
    /**
     * callback function
     */
    onClicked: {
      type: Function,
      required: true
    }
  }
};
</script>

<style scoped></style>
