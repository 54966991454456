import siteMutations from './mutations.js';
import siteActions from './actions.js';
import siteGetters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
        sites: [],
        currentSite: ""
    };
  },
  mutations: siteMutations,
  actions: siteActions,
  getters: siteGetters
};