class WalkingInstructions {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_ALL_INSTRUCTIONS: '/api/sites/:siteId/instructions',
            ADD_INSTRUCTION: '/api/sites/:siteId/instructions',
            GET_UPDATE_DELETE_INSTRUCTION: '/api/sites/:siteId/instructions/:instructionId'
        };
    }

    all() {
        return this.api.get(this.EP.GET_ALL_INSTRUCTIONS);
    }

    add(data) {
        return this.api.post(
            this.EP.ADD_INSTRUCTION,
            {
                domainId: data.domain ? data.domain.id : null,
                kioskId: data.kiosk ? data.kiosk.id : null,
                waitingRoomId: data.waitingRoom ? data.waitingRoom.id : null,
                regimeId: data.regime ? data.regime.id : null,
                arrowAngle: data.arrowAngle,
                validUntil: data.validUntil,
                validFrom: data.validFrom,
                registerAppointment: data.registerAppointment || false,
                key: data.standardText ? data.standardText.key : null,
                createdBy: 1, // TODO send login user ID here
                updatedBy: 1
            }
        );
    }

    update(data) {
        return this.api.put(
            this.EP.GET_UPDATE_DELETE_INSTRUCTION,
            {
                domainId: data.domain ? data.domain.id : null,
                kioskId: data.kiosk ? data.kiosk.id : null,
                regimeId: data.regime ? data.regime.id : null,
                waitingRoomId: data.waitingRoom ? data.waitingRoom.id : null,
                arrowAngle: data.arrowAngle,
                validUntil: data.validUntil,
                validFrom: data.validFrom,
                registerAppointment: data.registerAppointment || false,
                key: data.standardText ? data.standardText.key : null
            },
            {
                instructionId: data.id,
                createdBy: 1, // TODO send login user ID here
                updatedBy: 1
            }
        );
    }

    get(instructionId) {
        return this.api.get(
            this.EP.GET_UPDATE_DELETE_INSTRUCTION,
            {
                instructionId: instructionId
            }
        );
    }

    delete(instructionId) {
        return this.api.del(
            this.EP.GET_UPDATE_DELETE_INSTRUCTION,
            {
                instructionId: instructionId
            }
        );
    }

}

export default WalkingInstructions;