import userMutations from './mutations.js';
import userActions from './actions.js';
import userGetters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
        currentUser: {
            id: null,
            name: "",
            role: "",
            domainTypes: []
        },
    };
  },
  mutations: userMutations,
  actions: userActions,
  getters: userGetters
};