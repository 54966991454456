import store from "@/store";
import axios from 'axios';

class RestClientConfig {

    constructor(config) {
        // TODO: Sanity check
        this.apiConfig = config;
    }

    // get(endPoint, pathParams = {}) {
    //     let path = this._format(endPoint, pathParams);
    //
    //     return axios.get(path, this._getHeaders());
    // }
    get(endPoint, pathParams = {},queryParams = {}) {
        let api = this;
        let path = this._format(endPoint, pathParams);
        let config = Object.assign({params:queryParams},api._getHeaders())
        return axios.get(path,config);
    }

    post(endPoint, data = {}, pathParams = {}) {
        let path = this._format(endPoint, pathParams);

        return axios.post(path, data);
    }

    put(endPoint, data = {}, pathParams = {}) {
        let path = this._format(endPoint, pathParams);

        return axios.put(path, data);
    }

    del(endPoint, pathParams = {}) {
        let path = this._format(endPoint, pathParams);

        return axios.delete(path);
    }

    _getHeaders() {
        var headers = {
            withCredentials: true
        };

        return headers
    }

    // Underscore to suggest it's private
    _format(endPoint, pathParams = {}) {
        if (endPoint.includes(':siteId')) {
            pathParams['siteId'] = store.getters["sites/getCurrentSite"].id;
        }

        for (const key in pathParams) {
            const value = pathParams[key];
            endPoint = endPoint.replaceAll(":" + key, value);
        }

        if (!endPoint.startsWith("/")) {
            endPoint = "/" + endPoint;
        }

        return this.apiConfig.host + endPoint;
    }
}

export default RestClientConfig;