export default {
    setUserName(context, payload) {
        context.commit('setUserName', payload)
    },
    setUserId(context, payload) {
        context.commit('setUserId', payload);
    },
    setUserRole(context, payload) {
        context.commit('setUserRole', payload);
    },
    setUserDomainTypes(context, payload) {
        context.commit('setUserDomainTypes', payload);
    }
}