<template>
  <div>
    <div class="w3-container w3-margin-bottom zero">
      <loading v-bind:dspin="!contentLoaded"></loading>
    </div>

    <div class="w3-container flow-card blue-top w3-animate-opacity flow-rooms-content blue-top w3-padding" v-show="contentLoaded">
      <table  class="w3-table w3-bordered flow-table">
        <thead class="flow-blue-color">
        <th>{{ $t("publication-date") }}</th>
        <th>{{ $t("message-importance") }}</th>
        <th>{{ $t("title") }}</th>
        <th>{{ $t("expiry-date") }}</th>
        <th>{{ $t("updated-by") }}</th>
        <th></th>
        </thead>

        <tbody v-show="filteredMessages.length === 0">
        <tr>
          <td class="w3-center flow-blue-color" colspan="8">
            <b class="font-size-1pt25em">{{ $t("noMessages") }}</b>
          </td>
        </tr>
        </tbody>

        <tbody v-show="filteredMessages.length !== 0" class="cursor-pointer">
        <tr v-for="message in filteredMessages"
            v-bind:id="message.id" :key="message.id"
            @click="showEditForm(message)">
          <td>
            <span :style="{color: getTextColor(message)}">  {{ $t(message.publicationDate) }}</span>
          </td>
          <td>
              <span :style="{color: getTextColor(message)}">
                {{$t(message.messageImportance)}} </span>
          </td>
          <td>
              <span :style="{color: getTextColor(message)}">
                {{message.title}} </span>
          </td>
          <td>
              <span :style="{color: getTextColor(message)}">
                {{message.expiryDate}} </span>
          </td>
          <td>
              <span :style="{color: getTextColor(message)}">
                {{message.updatedBy}} </span>
          </td>
          <td v-if="!openEditForm && !openNewForm">
            <span>
                  <span
                      class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                      @click.stop.prevent="deleteMessage(message)"
                      v-if="showDelete(message)">
                      <i class="fa fa-trash w3-text-deep-orange"></i>
                      <span
                          class="w3-text-deep-orange flow-margin-left font-weight-bold w3-large">{{
                          $t('delete')
                        }}</span>
                  </span>
              </span>
          </td>

        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/common/Loading";

export default {
  name: 'MessageList',
  components: {Loading},
  props: ['newMessageAdded','openEditForm','openNewForm'],
  emits: ['openMessageDetails'],

  data() {
    return {
      contentLoaded: false,
      messages: []
    }
  },

  mounted() {
    this.getAllMessages();
  },

  watch: {
    newMessageAdded() {
      console.log(this.newMessageAdded);
      this.getAllMessages();
    }
  },

  computed: {
      filteredMessages() {
          return this.messages.filter(item => new Date().getFullYear() - new Date(Date.parse(item.publicationDate)).getFullYear() < 3 );
      },
  },

  methods: {
    getAllMessages() {
      this.contentLoaded = false;
      const app = this;
      app.texts = [];
      app.$restClient.messages.all().then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          app.messages = data;
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    showEditForm(message) {
      this.$emit('openMessageDetails', message);
    },

    deleteMessage(message) {
      const app = this;
      app.$restClient.messages.delete(message.id).then(response => {
        let data = response.data;
        if (data.length > 0) {
          app.messages = data;
        }
        this.getAllMessages();
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed"), message: this.$t("")});
      })
    },

    isDeleted(_message) {
        return !!(_message.comment.includes("Dit bericht wordt niet meer getoond aan de gebruikers")
            && _message.description.includes("Dit bericht wordt niet meer getoond aan de gebruikers"));
    },

    getTextColor(_message) {
      let today = new Date();
      let publicationDate = new Date(Date.parse(_message.publicationDate));
      if(today.getFullYear() - publicationDate.getFullYear() > 1 )
        return "gray";
      if(Date.parse(_message.expiryDate) < today) return "gray";
      return "";
    },

    showDelete(_message) {
      return _message.description != null &&
          !_message.description.includes('Dit bericht wordt niet meer getoond aan de gebruikers') &&
          Date.parse(_message.expiryDate) > new Date();
    }

  }
}
</script>