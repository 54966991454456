<template>
  <p class="w-100pc flex-col space-between-columns">
    <slot></slot>
  </p>
</template>
<script>

//TODO: This component needs to be fine-tuned
export default {
  name: "Column",
  props: {}

};
</script>

<style>
.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items at the start of the container */
  align-items: flex-start; /* Align items to the top */
}

.space-between-columns .grid-component {
  margin-bottom: 20px;
}

.space-between-columns .form-button {
  /*margin-top: 50px;*/
}
</style>