<template>
  <div class="w3-left-align">
    <i class="fa fa-clock flow-blue-color w3-xlarge w3-margin-right"></i>
    <content-header v-bind:title="$t('addNewOpeningHour')"></content-header>

    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <Column>
        <Row>
          <div class="grid-component">
            <label class="w3-left flow-blue-color flow-padding-bottom">{{
              $t("day")
            }}</label>
            <div class="w3-col w-100pc flow-margin-right sort-select">
              <StandardSelect
                class="w3-border w3-round-medium"
                type="text"
                :items="days"
                :valueKey="'value'"
                :valueKeyIndex="'key'"
                :callback="setSelectedDay"
              />
            </div>
          </div>
          <div class="grid-component">
            <label class="w3-left flow-blue-color flow-padding-bottom">{{
              $t("regime")
            }}</label>
            <div class="w3-col w-100pc flow-margin-right sort-select">
              <StandardSelect
                class="w3-border w3-round-medium"
                type="text"
                :items="regimes"
                :valueKey="'name'"
                :valueKeyIndex="'id'"
                :callback="setSelectedRegime"
              />
            </div>
          </div>
        </Row>

        <Row>
          <div class="grid-component">
            <StandardTextBox
              :text="startTime"
              :np-name-error="startTimeError"
              :label-name="$t('start-time')"
              :placeholder="$t('08:00')"
              @update-text="text => (startTime = text)"
            />
          </div>
          <div class="grid-component">
            <StandardTextBox
              :np-name-error="endTimeError"
              :text="endTime"
              :label-name="$t('end-time')"
              :placeholder="$t('20:00')"
              @update-text="text => (endTime = text)"
            />
          </div>
        </Row>
      </Column>
      <Column>
        <Row>
          <div class="form-button">
            <SaveButton
              :function-executed="createExecuted"
              :on-add="
                () => {
                  createNewOpeningHour();
                }
              "
            />
          </div>

          <div class="form-button">
            <BackButton :on-clicked="() => sideBarStatusEvent('close')" />
          </div>
        </Row>
      </Column>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ContentHeader from "@/components/common/ContentHeader.vue";
import StandardTextBox from "@/components/common/StandardTextBox.vue";
import Column from "@/components/common/grid/Column.vue";
import Row from "@/components/common/grid/Row.vue";
import SaveButton from "@/components/common/buttons/SaveButton.vue";
import BackButton from "@/components/common/buttons/BackButton.vue";
import StandardSelect from "@/components/views/opening-hours/StandardSelect.vue";

export default {
  name: "CreateOpeningHourSideBar",
  components: {
    StandardSelect,
    BackButton,
    SaveButton,
    Row,
    Column,
    StandardTextBox,
    ContentHeader
  },
  emits: ["success", "failure"],
  props: ["fetchedRegimes", "domainId"],
  data() {
    return {
      createExecuted: false,
      selectedDay: "MONDAY",
      selectedRegime: this.fetchedRegimes[0],
      startTime: "01:00",
      endTime: "22:00",
      startTimeError: "",
      endTimeError: "",
      npNameError: undefined,
      days: [
        {
          key: "MONDAY",
          value: this.$t("MONDAY")
        },
        {
          key: "TUESDAY",
          value: this.$t("TUESDAY")
        },
        {
          key: "WEDNESDAY",
          value: this.$t("WEDNESDAY")
        },
        {
          key: "THURSDAY",
          value: this.$t("THURSDAY")
        },
        {
          key: "FRIDAY",
          value: this.$t("FRIDAY")
        },
        {
          key: "SATURDAY",
          value: this.$t("SATURDAY")
        },
        {
          key: "SUNDAY",
          value: this.$t("SUNDAY")
        }
      ],
      regimes: this.fetchedRegimes
    };
  },
  methods: {
    ...mapActions({
      sideBarStatusEvent: "eventHandlers/openingHourSideBarStatusEvent"
    }),
    setSelectedDay(day) {
      this.selectedDay = day;
    },
    setSelectedRegime(regime) {
      this.selectedRegime = regime;
    },
    /**
     * This creates a new domainTiming for each existing domain of the current site and returns a domainTiming of 1 domain
     */
    createNewOpeningHour() {
      if (!this.isInputValid()) return;
      this.createExecuted = true;

      const createOpeningHourRequest = {
        id: null,
        day: this.selectedDay.key,
        startTime: this.startTime,
        endTime: this.endTime,
        regimeId: this.selectedRegime.id
      };

      this.$restClient.domainTimings
        .createDomainTimingsByRegime(
          createOpeningHourRequest,
          this.domainId,
          this.selectedRegime.id
        )
        .then(response => {
          this.$emit("success", response);
        })
        .catch(err => {
          console.log(err);
          this.$emit("failure", err);
        });
    },
    /**
     * @return Boolean
     */
    isInputValid() {
      let startTimeError = "";
      let endTimeError = "";

      if (this.startTime === "")
        startTimeError = this.$t("INVALID_TEXTFIELD");

      if (this.endTime === "") endTimeError = this.$t("INVALID_TEXTFIELD");

      if (!this.$helper.isTimeValid(this.startTime))
        startTimeError = this.$t("INVALID TIME");
      if (!this.$helper.isTimeValid(this.endTime))
        endTimeError = this.$t("INVALID TIME");

      if (this.startTime >= this.endTime) {
        startTimeError = this.$t("INVALID TIME PERIOD");
        endTimeError = this.$t("INVALID TIME PERIOD");
      }

      this.startTimeError = startTimeError;
      this.endTimeError = endTimeError;

      return this.startTimeError === "" && this.endTimeError === "";
    },
  }
};
</script>
