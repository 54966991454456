<template>
  <div class="flow-visit-icon" v-bind:class="cls" :title="$t(status)">
    <img/>
    <i class="fa fa-circle w3-black fa-xs"></i>
  </div>
</template>

<script>
export default {
  name: "VisitStatus",
  props: ['status', 'showMarker'],
  computed: {
    cls() {
      var getCls = () => {
        switch (this.status) {
          case "ENDED":
            return "flow-icon-ended";
          case "CANCELLED":
            return "flow-icon-ended";
          case "SERVING":
            return "flow-icon-serving";
          case "ARRIVED":
            return "flow-icon-arrived";
          case "NOT_ARRIVED":
            return "flow-icon-not-arrived";
          case "NO_CALL":
            return "flow-icon-not-arrived";
          default:
            return "flow-icon-not-arrived";

        }
      };

      return getCls() + (this.showMarker === true ? " active" : "");
    }
  }
}
</script>