<template>
  <div class="w3-col w3-left-align">
    <ContentHeader v-bind:title="$t('domains')" />
    <div class="w3-right w3-margin-right flow-margin-top">
      <PlusButton />
    </div>
  </div>

  <div class="content-sidebars sidebars-60-40">
    <SideForm v-if="openForm()" :context="onSideFormStatus">
      <template #create>
        <NewDomain
          :types="typesList"
          @addedNewDomain="getAllDomains()"
          :standardTexts="standardTexts"
        />
      </template>
      <template #update>
        <DomainDetails />
      </template>
    </SideForm>

    <div class="w3-container w3-margin-bottom zero">
      <Loading v-bind:dspin="domainContentLoading" />
    </div>
    <StandardTable
      v-show="!domainContentLoading"
      :is-clickable="true"
      :no-content="$t('noDomains')"
      :move-table-left="openForm()"
      :column-definitions="columnDefinitions"
      :data="domains"
      @openSideBar="sideFormStatusEvent('update')"
      @selectedRecord="domain => selectDomain(domain)"
    />
  </div>
</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import NewDomain from "../domains/NewDomain";
import DomainDetails from "@/components/views/domains/DomainDetails";
import PlusButton from "@/components/common/buttons/PlusButton.vue";
import { mapActions, mapGetters } from "vuex";
import SideForm from "@/components/common/SideForm.vue";
import Loading from "@/components/common/Loading.vue";
import StandardTable from "@/components/common/StandardTable.vue";

export default {
  name: "Domains",
  components: {
    Loading,
    SideForm,
    PlusButton,
    DomainDetails,
    NewDomain,
    ContentHeader,
    StandardTable
  },

  data() {
    return {
      columnDefinitions: [
        { key: "name", label: this.$t("name"), dataKey: "name" },
        {
          key: "organization",
          label: this.$t("organization"),
          dataKey: "type.organization.name"
        },
        {
          key: "domainType",
          label: this.$t("domainType"),
          dataKey: "type.name"
        },
        {
          key: "domain-message",
          label: this.$t("domain-message"),
          dataKey: "notificationTextValue"
        }
      ],
      standardTexts: [],
      standardTextDictionary: {},
      typesList: [],
      domainContentLoading: true,
      selectedDomain: undefined,
      domains: []
    };
  },
  mounted() {
    this.getTypesList();
    this.getStandardTexts();
  },
  watch: {
    typesList(newValue) {
      if (newValue) this.domainTypesEvent(this.typesList);
    },
    standardTexts(newValue) {
      if (newValue) {
        this.standardTextsEvent(this.standardTexts);
        this.getAllDomains();
      }
    },
    onUpdatedDomain(newValue) {
      if (newValue) this.getAllDomains();
    }
  },
  created() {
    this.sideFormStatusEvent("close");
  },

  computed: {
    ...mapGetters({
      onSideFormStatus: "eventHandlers/onSideFormStatus",
      onUpdatedDomain: "eventHandlers/onUpdatedDomain"
    })
  },

  methods: {
    ...mapActions({
      sideFormStatusEvent: "eventHandlers/sideFormStatusEvent",
      selectDomainEvent: "eventHandlers/selectDomainEvent",
      domainTypesEvent: "eventHandlers/domainTypesEvent",
      standardTextsEvent: "eventHandlers/standardTextsEvent"
    }),
    getStandardTexts() {
      return this.$restClient.languages
        .allDomainLocalesForDefaultLanguage()
        .then(response => {
          this.standardTexts = response.data;
          this.standardTexts.forEach(standardText => {
            this.standardTextDictionary[standardText.key] = standardText.value;
          });
          this.standardTexts.unshift({ value: "", key: "" });
        })
        .catch(error => {
          this.$notify.notifyError({ title: this.$t("something-went-wrong") });
          console.log(error);
        });
    },

    getTypesList() {
      this.$restClient.domainTypes
        .all()
        .then(response => {
          let data = response.data;
          if (data.length > 0) {
            this.typesList = JSON.parse(JSON.stringify(data));
          }
        })
        .catch(err => {
          console.log(err);
          this.$notify.notifyError({ title: this.$t("failed") });
        });
    },

    getAllDomains() {
      if (this.domainContentLoading === false) this.domainContentLoading = true;

      this.$restClient.domains
        .all()
        .then(response => {
          const data = response.data;
          let domains;
          if (data.length > 0) {
            domains = JSON.parse(JSON.stringify(data));
            domains.forEach(domain => {
              domain.notificationTextValue = this.standardTextDictionary[domain.notificationTextKey];
            })
          }
          this.domains = domains;
          this.domainContentLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.$notify.notifyError({
            title: this.$t("failed")
          });
          this.domainContentLoading = false;
        });
    },

    openForm() {
      return (
        this.onSideFormStatus === "create" || this.onSideFormStatus === "update"
      );
    },
    selectDomain(domain) {
      this.selectDomainEvent(domain);
      this.sideFormStatusEvent("update");
    }
  }
};
</script>
