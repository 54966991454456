class WaitingRooms {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_ALL_ROOMS: '/api/sites/:siteId/waiting-rooms',
            ADD_ROOM: '/api/sites/:siteId/waiting-rooms',
            GET_UPDATE_ROOM: '/api/sites/:siteId/waiting-rooms/:waitingRoomId',
            MAKE_DEFAULT_ROOM: '/api/sites/:siteId/waiting-rooms/default/:waitingRoomId',
            DELETE_ROOM: '/api/sites/:siteId/waiting-rooms/:waitingRoomId',
            USERS_BY_ROOM: '/api/screen/:screenId/users'
        };
    }

    all() {
        return this.api.get(this.EP.GET_ALL_ROOMS);
    }

    add(data) {
        return this.api.post(
            this.EP.ADD_ROOM,
            {
                name: data.name,
                default: data.default,
                route: data.route,
                color: data.color,
                createdBy: 1, // TODO send login user ID here
                updatedBy: 1
            }
        );
    }

    update(data) {
        return this.api.put(
            this.EP.GET_UPDATE_ROOM,
            {
                id: data.id,
                name: data.name,
                default: data.default,
                color: data.color,
                route: data.route
            },
            {
                waitingRoomId: data.id,
                createdBy: 1, // TODO send login user ID here
                updatedBy: 1
            }
        );
    }

    get(waitingRoomId) {
        return this.api.get(
            this.EP.GET_UPDATE_DELETE_DESK,
            {
                waitingRoomId: waitingRoomId
            }
        );
    }

    makeDefault(data) {
        return this.api.put(
            this.EP.MAKE_DEFAULT_ROOM,
            {
                default: data.default
            },
            {
                waitingRoomId: data.id,
                createdBy: 1, // TODO send login user ID here
                updatedBy: 1
            }
        );
    }

    deleteWaitingRoom(roomId){
        return this.api.del(this.EP.DELETE_ROOM,{waitingRoomId:roomId});
    }

    usersByRoom(screenId){
        return this.api.get(
            this.EP.USERS_BY_ROOM,
            {
                screenId: screenId
            }
        );
    }

}

export default WaitingRooms;