<template>
  <div class="w3-container content-sidebar-right"> <!-- class: layout of sidebar | shows sidebar when openNewForm = true and openDetailsForm = false -->
    <i class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max" @click="sideBarStatusEvent('close')"></i> <!-- x icon for closing the sidebar -->
    <slot v-if="context === 'create'" name="create"></slot>
    <slot v-if="context === 'update'" name="update"></slot>
  </div>
</template>

<script>


import {mapActions} from "vuex";

export default {
  name: "OpeningHourSideBar",
  components: {},
  props: {
    /**
     * the value of this determines if the sidebar is closed or not and in what context it is open.
     * <p>
     * if the value is set to "create" then it shows the CreateOpeningHourSideBar, if it is set to "update" then it shows the UpdateOpeningHourSideBar.
     */
    context: {
      type: String,
      required: true,
      default: "close"
    }
},
  methods: {
    ...mapActions({
      /**
       * In this component this event is used to let the OpeningHours component know that the sideBar should be closed
       */
      sideBarStatusEvent: "eventHandlers/openingHourSideBarStatusEvent"
    })
  }
}
</script>