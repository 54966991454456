export default {
    setUserName(state,payload) {
        state.currentUser.name = payload;
    },
    setUserId(state,payload) {
        state.currentUser.id = payload;
    },
    setUserRole(state,payload) {
        state.currentUser.role = payload;
    },
    setUserDomainTypes(state,payload) {
        state.currentUser.domainTypes = payload;
    },
}