export default {
    openingHourSideBarStatusEvent(context, payload) {
        context.commit('openingHourSideBarStatusEvent', payload);
    },
    selectOpeningHourEvent(context, openingHour) {
        context.commit('selectOpeningHourEvent', openingHour);
    },
    selectDomainEvent(context, domain) {
        context.commit('selectDomainEvent', domain);
    },
    sideFormStatusEvent(context, payload) {
        context.commit('sideFormStatusEvent', payload);
    },
    domainTypesEvent(context, payload) {
        context.commit('domainTypesEvent', payload);
    },
    standardTextsEvent(context, payload) {
        context.commit('standardTextsEvent', payload);
    },
    createdDomainEvent(context, domain) {
        context.commit('createdDomainEvent', domain);
    },
    updatedDomainEvent(context, domain) {
        context.commit('updatedDomainEvent', domain);
    }
}