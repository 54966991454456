<template>
  <div class="w3-dropdown-click flow-select-list-container w-100pc" ref="flowSelect">
        <span @click.prevent="openList = !openList"
              class="w3-input cursor-pointer w3-padding flow-select-list-selected  w3-ripple"
              v-bind:style="hasBackgroundColor ? {'background-color': selectedItem.colorCode } : {}">
            <p class="w-90pc w3-show-inline-block zero flow-no-wrap qpp-text-align-start"
            >{{translation(selectedItem[valueKey]) }}</p>
            <i class="fa fa-chevron-down w3-right flow-green-other-color"
               v-bind:class="openList ? 'fa-rotate-180' : ''"></i>
        </span>
    <div v-bind:class="openList ? 'w3-show' : 'w3-hide'"
         class="w3-dropdown-content w3-bar-block w3-border w-100pc flow-select-list flow-card w3-animate-opacity">
            <span v-for="item in items"
                  v-bind:key=item.id
                  v-bind:class="item[valueKeyIndex] === selectedItem[valueKeyIndex] ? 'selected' : ''"
                  v-bind:style="hasBackgroundColor ? {'background-color': item.colorCode } : {}"
                  class="w3-bar-item cursor-pointer flow-select-item"
                  @click="selectItem(item)">
                <span
                    v-bind:class="item[valueKey] ? '' : 'null-value'">{{translation(item[valueKey])}}</span>
                <i class="fa fa-check flow-green-active-color w3-right" v-if="!hasBackgroundColor"></i>
            </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlowSelect",
  props: {
    'items': undefined,
    'valueKey': {
      type: String,
      default: ''
    },
    'valueKeyIndex': {
      type: String,
      default: ''
    },
    'mainItem': undefined,
    'callback': undefined,
    'preSelectedItem': undefined,
    'hasBackgroundColor': {
      type: Boolean,
      default: false
    },
    'translate': {
      type:Boolean,
      default: false
    }
  },
  created() {
    document.addEventListener("click", this.documentClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.documentClick);
  },
  data() {
    return {
      openList: false,
      selectedItem: {}
    }
  },
  watch: {
    items() {
      this.setup();
    },
    preSelectedItem() {
      this.setup();
    }
  },
  mounted() {
    this.setup();
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
      if (this.callback) {
        if (this.mainItem)
          this.callback(item, this.mainItem);
        else
          this.callback(item);
      }
      this.openList = false;
    },
    setup() {
      if (this.preSelectedItem) {
        this.selectItem(this.preSelectedItem);
      } else if (this.items && this.items.length > 0) {
        this.selectItem(this.items[0]);
      }
    },
    documentClick(e) {
      const app = this;
      const tp = app.$refs.flowSelect;
      if (!tp || !tp.contains(e.target)) {
        this.openList = false;
      }

    },
    translation(text){
      if(this.translate && text){
        return this.$t(text);
      } else return text;
    }
  }
}
</script>

<style scoped>


</style>