<template>
  <div>
    <div class="w3-col flow-card blue-top">
      <table class="w3-table w3-bordered flow-table visits-table">
        <thead class="">
          <tr class="table-header-row">
            <th>
              {{ $t("arrivalTime") }}
              <i
                @click="sortList('arrivalTime')"
                class="fas fa-sort cursor-pointer"
              ></i>
            </th>
            <th>
              {{ $t("appointmentTime") }}
              <i
                @click="sortList('appointmentTime')"
                class="fas fa-sort cursor-pointer"
              ></i>
            </th>
            <th>
              {{ $t("fullName") }}
              <i
                @click="sortList('lastName', 'firstName')"
                class="fas fa-sort cursor-pointer"
              ></i>
            </th>
            <th>
              {{ $t("domain") }}
              <i
                @click="sortList('domainTypeName')"
                class="fas fa-sort cursor-pointer"
              ></i>
            </th>
            <th v-if="!openCard">
              {{ $t("contact") }}
              <i
                @click="sortList('visitContactUser')"
                class="fas fa-sort cursor-pointer"
              ></i>
            </th>
            <th v-if="!openCard">
              {{ $t("location") }}
              <i
                @click="sortList('waitingRoomName')"
                class="fas fa-sort cursor-pointer"
              ></i>
            </th>
            <th v-if="!openCard">
              {{ $t("language") }}
              <i
                @click="sortList('languageCode')"
                class="fas fa-sort cursor-pointer"
              ></i>
            </th>
          </tr>
        </thead>

        <tbody v-show="visits.length === 0">
          <tr>
            <td colspan="8" class="w3-center flow-blue-color">
              <b class="font-size-1pt25em">{{ $t("novisits") }}</b>
            </td>
          </tr>
        </tbody>

        <tbody v-show="visits.length !== 0">
          <tr
            v-for="visit in sortedVisits"
            v-bind:key="visit.id"
            :class="rowColor(visit)"
          >
            <td>
              {{ visit.arrivalTime }}
            </td>
            <td>
              {{ visit.appointmentTime }}
            </td>

            <td class="primary" @click="visitSelected(visit)">
              <visit-status
                class="w3-show-inline-block w3-margin-right"
                :status="visit.status"
                :showMarker="true"
              ></visit-status>
              <span>{{ visit.lastName }}</span>
              <span>&nbsp;{{ visit.firstName }}</span>
              <span v-html="concatComment(visit)"></span>
              <span v-if="visit.externalCustomerId && !openCard"
                >&nbsp;- {{ visit.externalCustomerId }}</span
              >
            </td>

            <td>
              <span> {{ visit.domainTypeName }}</span>
              <span v-if="visit.appointmentType">
                - {{ visit.appointmentType }}</span
              >
            </td>

            <td v-if="!openCard">
              {{ visit.visitContactUser }}
            </td>

            <td v-if="!openCard">
              {{ visit.waitingRoomName }}
            </td>

            <td v-if="!openCard">
              {{ visit.languageCode }}
            </td>

            <td>
              <i
                v-if="visit.status === 'ARRIVED'"
                class="fa fa-print w3-text-blue cursor-pointer print-icon-width"
                :title="$t('print')"
                @click="printTicket(visit.visitId)"
              ></i>

              <i
                v-else-if="visit.status === 'NOT_ARRIVED'"
                class="fa fa-check-double flow-green-active-color cursor-pointer print-icon-width"
                :title="$t('set-arrived-and-print')"
                @click="setAsArrivedAndPrintTicket(visit.visitId)"
              ></i>
            </td>

            <td>
              <div v-if="visit.status === 'ARRIVED'">
                <AppointmentPriority
                  :visitId="visit.visitId"
                  :appointment-has-priority="visit.priority"
                  @priorityChanged="onPriorityChanged"
                ></AppointmentPriority>
              </div>
            </td>

            <td v-if="!ongoing">
              <i
                v-if="visit.status === 'ARRIVED'"
                class="fa fa-bullhorn w3-text-deep-orange cursor-pointer"
                :title="$t('call')"
                @click="callVisitor(visit.visitId)"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import VisitStatus from "../../common/VisitStatus";
import AppointmentPriority from "../../common/AppointmentPriority.vue";

export default {
  name: "visitsForCaller",
  components: { VisitStatus, AppointmentPriority },
  props: [
    "visits",
    "onSelectCallback",
    "openCard",
    "callVisitor",
    "ongoing",
    "onPriorityChanged",
    "printTicket",
    "setAsArrivedAndPrintTicket"
  ],
  data: function() {
    return {
      sortedVisits: [],
      currentColumn: null,
      currentColumn2: null,
      // Define an object to track sorting orders for each column
      sortOrders: {
        arrivalTime: "asc",
        appointmentTime: "asc",
        lastName: "asc",
        domainTypeName: "asc",
        visitContactUser: "asc",
        waitingRoomName: "asc",
        languageCode: "asc"
      }
    };
  },
  mounted() {
    this.currentColumn = this.$helper.getCookie("sortVisitorsColumn")
      ? this.$helper.getCookie("sortVisitorsColumn")
      : null;
    this.currentColumn2 = this.$helper.getCookie("sortVisitorsColumn2")
      ? this.$helper.getCookie("sortVisitorsColumn2")
      : null;
    console.log(
      "sort by column: " +
        this.currentColumn +
        " and column2: " +
        this.currentColumn2
    );
  },
  watch: {
    visits() {
      this.sortList(this.currentColumn, this.currentColumn2, false);
    },
    openCard() {}
  },
  methods: {
    rowColor(visit) {
      return {
        "crossed-threshold": visit.thresholdExceeded && visit.status === "ARRIVED",
        "flow-golden-yellow-bg-clr": visit.priority && visit.status === "ARRIVED"
      };
    },
    visitSelected(visit) {
      this.onSelectCallback(visit.visitId, visit.visitorId);
    },
    concatComment(visit) {
      let concatenatedComment = "";

      if (visit && (visit.visitComment || visit.visitorComment)) {
        concatenatedComment += " (";
        if (visit.visitorComment) {
          concatenatedComment += this.$helper.sanitizeHTML(
            visit.visitorComment
          );
        }
        if (visit.visitComment && visit.visitorComment) {
          concatenatedComment += ", ";
        }
        if (visit.visitComment) {
          concatenatedComment += this.$helper.sanitizeHTML(visit.visitComment);
        }
        concatenatedComment += ")";
      }

      return concatenatedComment;
    },
    sortList(column, column2 = null, toggle = true) {
      // Check if the clicked column is the same as the currently clicked column
      if (column === null) column = "arrivalTime";

      if (this.currentColumn === column) {
        // If it's the same column, toggle its sorting order if toggle is ON
        if (toggle === true) {
          this.sortOrders[column] =
            this.sortOrders[column] === "asc" ? "desc" : "asc";
        } else {
          // otherwise get order from cookie
          this.sortOrders[column] = this.$helper.getCookie("sortVisitorsOrder");
        }
      } else {
        // If it's a different column, set its sorting order to 'asc' and update the current column
        this.sortOrders[column] = "asc";
        this.currentColumn = column;
        this.currentColumn2 = column2;
      }

      this.$helper.setCookie("sortVisitorsColumn", this.currentColumn);
      this.$helper.setCookie("sortVisitorsOrder", this.sortOrders[column]);
      if (this.currentColumn2) {
        this.$helper.setCookie("sortVisitorsColumn2", this.currentColumn2);
      } else {
        this.$helper.setCookie("sortVisitorsColumn2", "");
      }

      // Separate priority visits
      let priorityVisits = this.visits.filter(visit => visit.priority);
      let nonPriorityVisits = this.visits.filter(visit => !visit.priority);

      // Sort both arrays
      if (this.sortOrders[column] === "asc") {
        this.$helper.sortByKey(priorityVisits, column, column2, "asc");
        this.$helper.sortByKey(nonPriorityVisits, column, column2, "asc");
      } else {
        this.$helper.sortByKey(priorityVisits, column, column2, "desc");
        this.$helper.sortByKey(nonPriorityVisits, column, column2, "desc");
      }

      // Combine the arrays, with priority visits first
      this.sortedVisits = [...priorityVisits, ...nonPriorityVisits];
    }
  }
};
</script>

<style scoped>
.print-icon-width {
  margin-right: 5px;
}

.table-header-row:hover {
  background-color: white;
}
</style>
