<template>
    <h1 class="flow-content-header w3-show-inline-block">
        <b>{{ title }}<span>.</span></b>
    </h1>
</template>
<script>
    export default {
        name: "ContentHeader",
        props: ['title']
    }
</script>