import {createStore} from 'vuex';
import createPersistedState from "vuex-persistedstate";

import rootMutations from './mutations.js';
import rootActions from './actions.js';
import rootGetters from './getters.js';
import userModule from './modules/users/index.js';
import siteModule from './modules/sites/index.js';
import eventHandlerModule from './modules/eventHandlers/index.js'

const store = createStore({
    plugins: [createPersistedState()],
    modules: {
        users: userModule,
        sites: siteModule,
        eventHandlers: eventHandlerModule
    },
    state: {
    },
    mutations: rootMutations,
    actions: rootActions,
    getters: rootGetters
});
export default store;