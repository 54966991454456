<template>
    <div class="w3-container w3-center" v-show="dspin">
        <div>
            <h5 class="flow-blue-color zero-bottom w3-center">
                    <span class="flow-margin-left font-weight-bold  w3-margin-top">
                        {{ $t('loading') }}
                    </span>
                    <p class="zero">
                        {{ $t('pleaseWait') }}
                    </p>
            </h5>
        </div>
    </div>

    <div class="w3-container w3-center loading" v-bind:style="{display: this.dspin ? '' : 'none'}">
        <div class="w3-margin-top w3-col w3-cell-middle loading-container"
             v-bind:style="{display: this.dspin ? 'inline-block' : 'none'}">
            <span><i class="fa fa-circle fa-sm"></i></span>
            <span><i class="fa fa-circle fa-sm"></i></span>
            <span><i class="fa fa-circle fa-sm"></i></span>
            <span><i class="fa fa-circle fa-sm"></i></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SpinWhileLoading",
        props: ['dspin']
    }
</script>