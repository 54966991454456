<template>
  <span
    :title="$t('editForm')"
    class="fa fa-stack w3-large cursor-pointer zero w3-ripple w3-margin-right"
    @click="openCard(0)"
  >
    <i
      class="fa fa-stack-2x fa-circle w3-text-white transform-animation"
      v-bind:class="openEditForm ? 'halo-text' : ''"
    ></i>
    <i class="flow-blue2-text fa fa-stack-1x fa-cogs w3-xlarge"></i>
  </span>

  <span
    :title="$t('timings')"
    class="fa fa-stack w3-large cursor-pointer zero w3-ripple w3-margin-right"
    @click="openCard(1)"
  >
    <i
      class="fa fa-stack-2x fa-circle w3-text-white transform-animation"
      v-bind:class="openTimings ? 'halo-text' : ''"
    ></i>
    <i class="fa fa-stack-1x fa-clock w3-xlarge"></i>
  </span>

  <div v-if="openEditForm">
    <EditDomainForm />
  </div>
  <div v-if="openTimings">
    <span class="w-90pc w3-display-middle"> {{ $t("timingsText") }} </span>
  </div>
</template>

<script>
import EditDomainForm from "@/components/views/domains/EditDomainForm";
export default {
  name: "DomainDetails",
  components: { EditDomainForm },

  data() {
    return {
      openEditForm: true,
      openTimings: false
    };
  },
  methods: {
    openCard(index) {
      this.openEditForm = index === 0;
      this.openTimings = index === 1;
    }
  }
};
</script>

<style scoped></style>
