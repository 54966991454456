class Configuration {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_CONFIG_PROPERTY: '/api/sites/:siteId/app-config/:name'
        };
    }

    getDeskRefreshProperty() {
        return this.api.get(
            this.EP.GET_CONFIG_PROPERTY, {name: "desk_refresh_interval"}
        );
    }
    getVisitRefreshProperty() {
        return this.api.get(
            this.EP.GET_CONFIG_PROPERTY, {name: "visit_refresh_interval"}
        );
    }
}

export default Configuration;