<template>
  <div class="w-100pc flex-row grid-components">
    <slot></slot>
  </div>
</template>
<script>

//TODO: This component needs to be fine-tuned
export default {
  name: "Row",
  props: {}

};
</script>

<style>
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* Align items at the start of the container */
  align-items: flex-start; /* Align items to the top */
}

.grid-components .grid-component {
  margin-right: 20px; /* Add space between components */
  /*width: 60%; !* Each component takes 50% width of the container *!*/
  box-sizing: border-box; /* Include padding and border in the width */
  flex: 1;
}

.grid-components .form-button {
}

</style>